import React, { useState, useEffect } from 'react';
import { COOKIE_POLICY_KEY, MONTH_IN_MILLIS } from 'utils/constants';
import styles from './cookieConsent.module.scss';
import { className, hasLocalStorage } from 'utils';

const getLastShown = () => {
  const date =
    typeof window !== 'undefined' ? window.localStorage.getItem(COOKIE_POLICY_KEY) : null;
  return new Date(date ? parseInt(date) : null);
};

/**
 * Check that time difference between last shown
 * and today is more than a month.
 */
const shouldShowPolicy = date => {
  const now = new Date();
  const timeDifference = now.getTime() - date.getTime();

  return timeDifference >= MONTH_IN_MILLIS;
};

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  const removeCookies = () => {
    const cookies = document.cookie.split(';');

    const trackingDomains = [
      'hs-analytics.net',
      '.hsadspixel.net',
      'hs-banner.com',
      'hs-scripts.com',
      'doubleclick.net',
      'linkedin.com',
      'ads-twitter.com',
      'twitter.com',
      'google-analytics.com',
      'google.com',
      'googleadservices.com',
      'pardot.com',
      'facebook.com',
    ];

    cookies.forEach(cookie => {
      const cookieName = cookie.split('=')[0].trim();

      trackingDomains.forEach(domain => {
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${domain}`;
      });

      const currentDomain = window.location.hostname;
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=.${currentDomain}`;
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
    });
  };

  useEffect(() => {
    const lastShown = getLastShown();
    const consent = hasLocalStorage() ? localStorage.getItem('cookieConsent') : null;

    if (isNaN(lastShown.getTime()) || shouldShowPolicy(lastShown) || !consent) {
      setShowBanner(true);
    } else {
      setShowBanner(false);

      if (consent === 'rejected') {
        removeCookies();
      }
    }

    if (!consent) {
      removeCookies();
    }
  }, []);

  const updateCookiePreferences = preference => {
    localStorage.setItem('cookieConsent', preference);
    localStorage.setItem(COOKIE_POLICY_KEY, Date.now().toString());
    setShowBanner(false);
  };

  const onAcceptAll = () => {
    updateCookiePreferences('accepted');
  };

  const onRejectAll = () => {
    removeCookies();
    updateCookiePreferences('rejected');
    window.location.reload(true);
  };

  return (
    <>
      {showBanner && (
        <div className={styles.cookieConsent} data-testid="CookieConsent">
          <div className={styles.textWrapper}>
            <p className={styles.headline}>We value your privacy</p>
            <div className={styles.text}>
              <p>
                This website uses cookies and similar tools, some of which are provided by third
                parties, to operate and improve our website, enable social media and other features,
                provide a more tailored experience, and for advertising and marketing. These cookies
                and tools allow us and third parties to collect user data and communications, IP
                address and online identifiers, referring URLs and other content and browsing
                information, and user interactions with this site.
              </p>

              <p style={{ marginTop: '1rem' }}>
                By clicking “Accept All Cookies,” you consent to our use of cookies and similar
                tools to collect and share information and acknowledge and agree to our{' '}
                <a href="https://www.commonwealth.com/for-clients/disclosure/privacy-policy">
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
          <div className={styles.buttonWrapper}>
            <button {...className(styles.button, styles.reject)} onClick={onRejectAll}>
              Reject All
            </button>
            <button {...className(styles.button, styles.accept)} onClick={onAcceptAll}>
              Accept All
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
