import React from 'react';
import Helmet from 'react-helmet';

const HubspotPixel = () => {
  React.useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (window._hsp) {
      if (consent === 'accepted') {
        window._hsp.push(['acceptCookies']);
      } else {
        window._hsp.push(['revokeCookies']);
      }
    }
  }, []);

  // const consent = localStorage.getItem('cookieConsent') === 'accepted';

  // if (!consent) {
  //   console.log('Consent Rejected');
  //   return null;
  // }

  return (
    <Helmet>
      {/* Start of HubSpot Embed Code */}
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/295024.js"></script>
      {/* End of HubSpot Embed Code */}
    </Helmet>
  );
};

export default HubspotPixel;
